import React from "react"
import Layout from "../layouts/layout";
import {ReactComponent as PageNotFoundImage} from "../images/404_page_not_found_.svg";

export default function Home() {

    return (
        <Layout>
            <section className="text-gray-700 body-font">
                <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                    <PageNotFoundImage className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" />
                    <div className="text-center lg:w-2/3 w-full">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Page not found</h1>
                        <p className="mb-8 leading-relaxed">The Page you are looking for doesn't exist or another error occurred</p>
                        <div className="flex justify-center">
                            <a href="/" className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Go Back</a>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )

}
